<template>
  <v-data-table
    :items="drug.interactions"
    :headers="[
      { text: 'Drug', value: 'name.en' },
      { text: 'Severity', value: 'severity' },
      { text: 'Effect', value: 'interaction.effect' },
      // { text: 'Mechanism', value: 'interaction.mechanism' },
      // { text: 'Management', value: 'interaction.management' },
      { value: 'actions', align: 'end', sortable: false },
    ]"
    no-data-text="No interactions found"
    sort-by="name.en"
    show-expand
  >
    <template #top>
      <div class="d-flex justify-space-between">
        <h5 class="text-overline">
          Drug Interactions
        </h5>
        <v-spacer />
        <div>
          <drug-picker
            :value="drug.interactions"
            dense
            hide-arkscore
            hide-selected
            menu-props="closeOnClick, closeOnContentClick"
            multiple
            no-display
            no-hint
            prepend-inner-icon="fa fa-plus-circle success--text"
            xrounded
            small-chips
            outlined
            @input="v => updateDrugInteractions(v)"
          />
        </div>
      </div>
    </template>
    <template #item.severity="{ item }">
      <InteractionSeverityChip
        :severity="item.interaction.severity"
      />
    </template>
    <template #item.interaction.effect="{ item }">
      {{ item.interaction.effect | truncate(250) }}
    </template>
    <template #item.interaction.mechanism="{ item }">
      {{ item.interaction.mechanism | truncate(30) }}
    </template>
    <template #item.interaction.management="{ item }">
      {{ item.interaction.management | truncate(30) }}
    </template>
    <template #item.actions="{ item }">
      <v-edit-dialog
        v-if="$auth.check({ drugs: 'edit' })"
        :return-value.sync="item"
        save-text="Update"
        @close="updateDrugInteractions(drug.interactions)"
      >
        <v-btn
          icon
          small
        >
          <v-icon
            x-small
          >
            fal fa-edit
          </v-icon>
        </v-btn>
        <template #input>
          <v-col class="mt-4">
            <v-select
              v-model="item.interaction.severity"
              :items="['major', 'moderate', 'minor', 'contraindicated']"
              label="Severity"
              small-chips
              dense
            />
            <v-textarea
              v-model="item.interaction.effect"
              label="Effect"
            />
            <v-textarea
              v-model="item.interaction.mechanism"
              label="Mechanism"
              maxlength="500"
              counter
            />
            <v-textarea
              v-model="item.interaction.management"
              label="Management"
              maxlength="500"
              counter
            />
            <v-divider />
            <v-btn
              color="error"
              text
              @click="drug.interactions.splice(drug.interactions.indexOf(item), 1)"
            >
              Delete
            </v-btn>
          </v-col>
        </template>
      </v-edit-dialog>
    </template>
    <template #expanded-item="{ item }">
      <td colspan="100%">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Effect
              </v-list-item-title>
              {{ item.interaction.effect }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Mechanism
              </v-list-item-title>
              {{ item.interaction.mechanism }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Management
              </v-list-item-title>
              {{ item.interaction.management }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </td>
    </template>
  </v-data-table>
</template>
<script>
export default {
  components: {
    DrugPicker: () => import('@/components/drug/DrugPicker.vue'),
    InteractionSeverityChip: () => import('@/components/drug/InteractionSeverityChip.vue'),
  },
  props: {
    drug: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    severityColor(severity) {
      switch (severity) {
        case 'major':
          return 'red';
        case 'moderate':
          return 'orange darken-3';
        case 'minor':
          return 'yellow darken-3';
        case 'contraindicated':
          return 'blue-grey';
        default:
          return 'grey';
      }
    },
    updateDrugInteractions(value) {
      let interactions = []
      value.forEach(drug => {
        if (!drug.interaction) {
          drug.interaction = {
            severity: 'moderate',
            effect: '',
            mechanism: '',
            management: '',
          }
        }
        interactions.push(drug)
      })
      this.drug.interactions = interactions
    },
  },
}
</script>
